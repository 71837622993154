import React, { useEffect, useRef, useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { styled } from 'twin.macro';
import { down } from 'styled-breakpoints';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { FullAnswer } from '../../../entities/full-answer';
import Heading3 from '../../../components/common/headings/heading3';
import { strings } from '../../../localization/strings';
import CommonAnimatedLoader from '../../../components/common/animated/loader';
import GreyBlock from '../../../components/questionnaire/results/grey-block';
import Heading2 from '../../../components/common/headings/heading2';
import Allergen from '../../../components/questionnaire/results/allergen';
import LegendBlock from '../../../components/questionnaire/results/legend';
import IMAGES from '../../../assets/images';
import { pluralizeWithNumber } from '../../../services/grammar/pluralize';
import DoctorBlock from '../../../components/questionnaire/results/doctor-block';
import CommonButtonDefault from '../../../components/common/buttons/default';
import TriggerComponent from '../../../components/questionnaire/results/trigger';
import SourceGroup from '../../../components/questionnaire/results/source-group';
import InfoTooltip from '../../../components/questionnaire/results/info-tooltip';
import { getLongMonthName, getShortMonthName } from '../../../services/formatter/date';
import * as ordersApi from '../../../services/api/orders';
import { AllergensAnswer } from '../../../entities/allergens-answer';
import { useAuth } from '../../../hooks/use-auth';
import SymptomComponent from '../../../components/questionnaire/results/symptom';
import { allWords } from '../../../services/formatter/text';
import { accusative } from '../../../services/grammar/lithuanian/singular';
import { useSite } from '../../../hooks/use-site';
import { EatingSymptoms } from '../../../entities/foodId/eating-symptoms';
import { Habit } from '../../../entities/foodId/habits';
import { EatingSymptomGroup } from '../../../entities/foodId/eating-symptom-group';
import SourceGroupHeading from '../../../components/profile/source-group-heading';
import SourceGroupIcon from '../../../components/profile/source-group-icon';
import ProfileResultsFoodIdPreviewPageComponent from './foodId/review';
import HeadingResult from '../../../components/profile/heading-results';
import HeadingResultHighlight from '../../../components/profile/heading-result-highlight';

const HasBackground = styled.div<{ theme: boolean }>`
  overflow: hidden;
  @media screen {
    ${(props) => props.theme === 'allergomedica' && `background: url(${IMAGES.BLOB.BGRESULT}) no-repeat bottom center;`}
  }
`;

const MultiColumn = styled.div`
  column-count: 2;
  column-gap: 1rem;

  ${down('md')} {
    column-count: 1;
    column-gap: 0;
  }
`;

const MultiColumnItem = styled.div`
  break-inside: avoid;
`;

function ProfileResultsAllergenGroupsPageComponent() {
  const { theme, locale } = useSite();
  const { token, tokenAdmin } = useAuth();
  const { hash } = useParams();
  const { answer, isPreview, foodIdHabits, foodIdSymptoms, foodIdSymptomsGroups } = useOutletContext<{
    answer: FullAnswer;
    isPreview: boolean;
    foodIdHabits: Habit[];
    foodIdSymptoms: EatingSymptoms[];
    foodIdSymptomsGroups: EatingSymptomGroup[];
  }>();
  const [allergenGroups, setAllergenGroups] = useState<AllergensAnswer>();

  const sourceGroupsRefs = useRef<(HTMLDivElement | null)[]>([]);

  const assignSourceGroupRef = (el: HTMLDivElement | null, idx: number) => {
    sourceGroupsRefs.current[idx] = el;
  };

  const scrollToGroup = (idx: number) => {
    const offset = sourceGroupsRefs?.current[idx]?.offsetTop || 0;

    window.scrollTo({ behavior: 'smooth', top: offset - 10 });
  };

  useEffect(() => {
    const fetchAnswer = async () => {
      if (hash && answer && (answer.isReady || isPreview) && !answer.sourceGroups.length) {
        const res = await ordersApi.getAllergens(token || tokenAdmin, hash);

        setAllergenGroups(res);
      }
    };

    fetchAnswer();
  }, [answer, hash, isPreview, token, tokenAdmin]);

  const months = Array.from(Array(12)).map((n, i) => i + 1);

  return (
    <HasBackground theme={theme}>
      {!answer && <CommonAnimatedLoader />}
      {answer && (answer.isReady || isPreview) && (
        <>
          {theme === 'foodId' && (
            <ProfileResultsFoodIdPreviewPageComponent
              answer={answer}
              habits={foodIdHabits}
              foodIdSymptoms={foodIdSymptoms}
              foodIdSymptomsGroups={foodIdSymptomsGroups}
            />
          )}
          {theme === 'allergomedica' && (
            <div className="container max-w-4xl px-4 mx-auto pb-10 print:max-w-none">
              <HeadingResult>
                {answer.sourceGroups.length > 0 ? (
                  <>
                    {strings().test_answer.youAreSensitive}
                    <HeadingResultHighlight>
                      {pluralizeWithNumber(
                        answer.sourceGroups.length,
                        strings().test_answer.allergenGroupPlural,
                        locale,
                      )}
                    </HeadingResultHighlight>
                  </>
                ) : (
                  <div className="text-green-500 font-bold">
                    <CheckCircleIcon className="w-10 h-10 inline-flex mr-2" />
                    {strings().test_answer.noSensitivity}
                  </div>
                )}
              </HeadingResult>

              <div className="my-10 sm:my-20 print:!my-3">
                <DoctorBlock
                  name={answer.doctor.name}
                  position={strings().test_answer.doctorPosition[answer.doctor.key]}
                  image={answer.doctor.key ? IMAGES.DOCTORS[answer.doctor.key].IMAGE : undefined}
                  signature={answer.doctor.key ? IMAGES.DOCTORS[answer.doctor.key].SIGNATURE : undefined}
                  text={answer.comments.final || ''}
                />
              </div>

              {answer.sourceGroups.map((sourceGroup, sourceGroupIdx) => (
                <button
                  type="button"
                  className="w-full print:hidden"
                  key={`${sourceGroup.id}-${sourceGroupIdx + 1}`}
                  onClick={() => scrollToGroup(sourceGroupIdx)}
                >
                  <SourceGroup
                    title={sourceGroup.customTitle || sourceGroup.sourceGroupTitle}
                    strength={sourceGroup.strength}
                    iconName={sourceGroup.iconName}
                    symptoms={sourceGroup.symptoms}
                  />
                </button>
              ))}

              {answer.sourceGroups.length > 0 ? (
                <div className="mb-10 md:mb-20 print:hidden">
                  <LegendBlock />
                </div>
              ) : (
                <div>
                  <div className="text-center mb-3 text-md md:text-lg">
                    {strings().test_answer.noSensitivityAllergensListTitle.replace(
                      '{num}',
                      `${strings().products[answer.productKey].allergens.replace(
                        '{allergensCount}',
                        answer.allergensCount,
                      )}`,
                    )}
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {allergenGroups?.groups.map((group, key) => (
                      <div className="" key={`${group.id}-${key + 1}`}>
                        <Allergen full title={group.title} strength={0} iconName={group.iconName} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Nenustatytos kilmės simptomai */}
              {answer.unidentifiedSymptoms.length > 0 && (
                <div className="my-5 md:my-10 print:!my-3">
                  <Heading3 className="mb-3 md:mb-7 text-center print:text-left print:!mb-1">
                    {strings().test_answer.unidentifiedSymptoms.title}
                  </Heading3>
                  {answer.comments.unidentifiedSymptoms && <GreyBlock text={answer.comments.unidentifiedSymptoms} />}
                  <div className="flex flex-1 flex-wrap -mx-2 mt-5 print:mt-1 print:mx-0">
                    {answer.unidentifiedSymptoms.map((symptom, key) => (
                      <div className="px-2 print:pl-0 print:pr-2 w-auto" key={`disease-${symptom.id}-${key + 1}`}>
                        <TriggerComponent
                          activeItem
                          title={symptom.title}
                          key={`disease-${symptom.id}-symptom-${symptom.id}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Detalus alergenų profilis */}
              {!!answer.sourceGroups.length && (
                <div className="text-center py-7 md:py-10 mt-7 md:mt-12 print:hidden">
                  <HeadingResult>
                    <HeadingResultHighlight>
                      {strings().test_answer.detailedAllergenProfile.title}
                    </HeadingResultHighlight>
                  </HeadingResult>
                  <div>{strings().test_answer.detailedAllergenProfile.description}</div>
                </div>
              )}

              {answer.sourceGroups.map((sourceGroup, sourceGroupIdx) => (
                <div
                  className="bg-white rounded-lg md:rounded-xl overflow-hidden block-shadow mt-8 md:mt-16 print:!mt-1 print:border-t print:border-black print:!rounded-none"
                  key={`source-group-${sourceGroup.id}-${sourceGroupIdx}`}
                  ref={(el) => assignSourceGroupRef(el, sourceGroupIdx)}
                >
                  <SourceGroupHeading strength={sourceGroup.strength}>
                    <div className="flex-shrink-0 mr-3 sm:mr-5 md:mr-8 print:hidden">
                      <SourceGroupIcon strength={sourceGroup.strength}>
                        <span className={`icon am-icon-${sourceGroup.iconName}`} />
                      </SourceGroupIcon>
                    </div>
                    <div className="flex flex-1 flex-col print:flex-row print:justify-start justify-center">
                      <Heading2 className="!mb-0 md:mb-2 print:!mb-1 print:mr-1">
                        {sourceGroup.customTitle || sourceGroup.sourceGroupTitle}
                      </Heading2>
                      {strings().test_answer.sensitivity[sourceGroup.strength]}
                      {sourceGroup.symptoms &&
                        (sourceGroup.symptoms.length > 0 ? (
                          <>
                            {strings().test_answer.sensitivitySuffix}
                            <span className="font-bold">
                              {sourceGroup.symptoms.length > 3
                                ? strings().test_answer.manySymptoms
                                : sourceGroup.symptoms
                                    ?.map((symptom) =>
                                      symptom.titleKa && locale === 'pl'
                                        ? symptom.titleKa.toLowerCase()
                                        : allWords(symptom.title.toLowerCase(), accusative, locale),
                                    )
                                    .join(', ')}
                            </span>
                          </>
                        ) : (
                          strings().test_answer.noSymptoms
                        ))}
                    </div>
                  </SourceGroupHeading>
                  <div className="p-5 md:p-10 print:!p-1">
                    <div className="sm:grid sm:grid-cols-2 gap-4 mb-6 print:mb-1">
                      <div className="items-center">
                        <Heading3 className="flex">
                          <div>{strings().test_answer.primaryAllergen.title}</div>

                          {strings().test_answer.primaryAllergen.tooltip && (
                            <InfoTooltip>{strings().test_answer.primaryAllergen.tooltip}</InfoTooltip>
                          )}
                        </Heading3>
                        {/* Pagrindinis alergenas */}
                        <div className="flex flex-1 flex-wrap -mx-2">
                          {!sourceGroup.primarySources.length && (
                            <div className="px-2">{strings().test_answer.noPrimaryAllergen}</div>
                          )}
                          {sourceGroup.primarySources.map((primarySource, key) => (
                            <div
                              className="px-2 mt-3 md:mt-4 print:!mt-1 print:px-1"
                              key={`sg-${sourceGroup.id}-ps-${primarySource.id}-${key + 1}`}
                            >
                              <Allergen
                                title={primarySource.sourceTitle}
                                strength={primarySource.strength}
                                iconName={primarySource.iconName}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="mt-2 sm:mt-0">
                        {answer.productKey !== 'FULL' && answer.productKey !== 'SCREENER_ASIT' ? (
                          <>
                            <Heading3 className="flex">
                              <div>{strings().test_answer.components.title}</div>
                              {strings().test_answer.components.tooltip && (
                                <InfoTooltip>{strings().test_answer.components.tooltip}</InfoTooltip>
                              )}
                            </Heading3>
                            {answer.productKey === 'FREE_SCREENER_ASIT' ? (
                              <div className="flex flex-1 flex-wrap -mx-2">
                                {/* FREE Screener asit - source grupes (komponentus) rodom tik įkvepiamiems alergenams */}
                                {sourceGroup.allergens
                                  .filter((a) => a.strength > 0 && a.isAirway)
                                  .map((a, key) => (
                                    <div className="px-1 min-w-20" key={`allergen-${a.id}-${key + 1}`}>
                                      <div className="rounded-full px-5 py-2 mt-3 md:mt-4 md:p-1 md:w-20 md:h-20 text-sm inline-flex justify-center items-center border border-content-400 text-center print:border-0 print:!w-auto print:!h-auto print:!mt-0">
                                        {a.componentCode}
                                      </div>
                                    </div>
                                  ))}
                                <div className="text-content-700 mt-3 text-md">
                                  {!sourceGroup.allergens.find((a) => a.isAirway) && (
                                    <div>{strings().test_answer.components.onlyInFullResult}</div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="text-content-700 mt-3 text-md">
                                <div>{strings().test_answer.components.onlyInFullResult}</div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div>
                            {/* Full title rodom tik jei yra source group */}
                            {!!sourceGroup.allergens.length && answer.productKey === 'FULL' && (
                              <Heading3 className="flex">
                                <div>{strings().test_answer.components.title}</div>
                                {strings().test_answer.components.tooltip && (
                                  <InfoTooltip>{strings().test_answer.components.tooltip}</InfoTooltip>
                                )}
                              </Heading3>
                            )}
                            {/* Screener_Asit rodom title visada, o alt teksta, jei nera source group */}
                            {answer.productKey === 'SCREENER_ASIT' && (
                              <>
                                <Heading3 className="flex">
                                  <div>{strings().test_answer.components.title}</div>
                                  {strings().test_answer.components.tooltip && (
                                    <InfoTooltip>{strings().test_answer.components.tooltip}</InfoTooltip>
                                  )}
                                </Heading3>
                                {!sourceGroup.allergens.length && (
                                  <div className="text-content-700 mt-3 text-md">
                                    <div>{strings().test_answer.components.onlyInFullResult}</div>
                                  </div>
                                )}
                              </>
                            )}

                            {/* Full ir Screener_Asit ištirime source groups (komponentus) rodom visur, jei jie yra */}
                            <div className="flex flex-1 flex-wrap -mx-2">
                              {sourceGroup.allergens
                                .filter((a) => a.strength > 0)
                                .map((a, key) => (
                                  <div className="px-1 min-w-20" key={`sourceGroup-allergen-${a.id}-${key + 1}`}>
                                    <div className="rounded-full px-5 py-2 mt-3 md:mt-4 md:p-1 md:w-20 md:h-20 text-sm inline-flex justify-center items-center border border-content-400 text-center print:border-0 print:!w-auto print:!h-auto print:!mt-0">
                                      {a.componentCode}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {sourceGroup.commentMainSource && (
                      <div className="mb-3 md:mb-0">
                        <GreyBlock text={sourceGroup.commentMainSource} />
                      </div>
                    )}

                    {!!sourceGroup.secondarySources.length && (
                      <div className="md:pt-11 print:!pt-2">
                        <Heading3 className="flex">
                          <div>{strings().test_answer.secondaryAllergens.title}</div>

                          {strings().test_answer.secondaryAllergens.tooltip && (
                            <InfoTooltip>{strings().test_answer.secondaryAllergens.tooltip}</InfoTooltip>
                          )}
                        </Heading3>
                        {/* antriniai alergenai */}
                        {answer.productKey === 'SCREENER' || answer.productKey === 'FREE_SCREENER' ? (
                          <div className="text-content-700 mt-3 text-md">
                            {strings().test_answer.secondaryAllergens.onlyInFullResult}
                          </div>
                        ) : (
                          <>
                            <div className="flex flex-1 flex-wrap -mx-2 pt-3 md:pt-7 print:!pt-2">
                              {sourceGroup.secondarySources.map((secondarySource, key) => (
                                <div
                                  className="px-2 mb-2"
                                  key={`sg-${sourceGroup.id}-ss-${secondarySource.id}-${key + 1}-${
                                    secondarySource.sourceTitle
                                  }`}
                                >
                                  <Allergen
                                    title={secondarySource.sourceTitle}
                                    strength={secondarySource.strength}
                                    iconName={secondarySource.iconName}
                                  />
                                </div>
                              ))}
                            </div>
                            {sourceGroup.commentSecondarySource && (
                              <div className="mt-3 md:mt-7 print:!mt-1">
                                <GreyBlock text={sourceGroup.commentSecondarySource} />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    {(sourceGroup.commentSymptoms || !!sourceGroup.symptomGroups.length) && (
                      <div className="pt-5 md:pt-11 print:!pt-3">
                        <Heading3 className="mb-5 flex">
                          <div>{strings().test_answer.relatedSymptoms.title}</div>

                          {strings().test_answer.relatedSymptoms.tooltip && (
                            <InfoTooltip>{strings().test_answer.relatedSymptoms.tooltip}</InfoTooltip>
                          )}
                        </Heading3>
                        <div className="flex flex-wrap -mx-2 print:mx-0">
                          <MultiColumn className="mb-5 print:mb-1">
                            {sourceGroup.symptomGroups.map((symptomGroup) => (
                              <MultiColumnItem className="mb-5 print:mb-1" key={symptomGroup.id}>
                                <div className="mb-3 font-bold print:mb-1">{symptomGroup.title}</div>

                                {symptomGroup.symptoms.map((symptom) => (
                                  <div className="w-full md:w-auto" key={`sg-${symptom.id}`}>
                                    <SymptomComponent
                                      title={symptom.title}
                                      activeItem={symptom.active}
                                      key={`symptom-${symptom.id}`}
                                    />
                                  </div>
                                ))}
                              </MultiColumnItem>
                            ))}
                          </MultiColumn>
                        </div>

                        {sourceGroup.commentSymptoms && <GreyBlock text={sourceGroup.commentSymptoms} />}
                      </div>
                    )}

                    {(sourceGroup.commentPeriod || !!sourceGroup.months.length) && (
                      <div className="pt-5 md:pt-11 print:!pt-3">
                        <Heading3 className="mb-5 print:!mb-1 flex">
                          <div>{strings().test_answer.activePeriod.title}</div>

                          {strings().test_answer.activePeriod.tooltip && (
                            <InfoTooltip>{strings().test_answer.activePeriod.tooltip}</InfoTooltip>
                          )}
                        </Heading3>

                        <div className="mb-3">
                          <div className="bg-content-300 rounded-xl w-full grid grid-cols-12 justify-items-stretch">
                            {months.map((month) => (
                              <div
                                key={month}
                                className={classNames('text-center text-xs py-3 print:py-1', {
                                  'border-r': month !== 12,
                                  'bg-blue-800 border-blue-900 text-blue-600': sourceGroup.months.indexOf(month) !== -1,
                                  'border-content-500 text-content-800': sourceGroup.months.indexOf(month) === -1,
                                })}
                              >
                                <span className="hidden md:block">{getLongMonthName(month, locale)}</span>
                                <span className="hidden sm:block md:hidden">{getShortMonthName(month, locale)}</span>
                                <span className="block sm:hidden">{getShortMonthName(month, locale, 2)}</span>
                              </div>
                            ))}
                          </div>
                          <div className="block sm:hidden text-sm text-content-700 pl-1 mt-1">
                            {strings().test_answer.activePeriod.months}
                          </div>
                        </div>
                        {/* <div className="flex flex-wrap -mx-2"> */}
                        {/*   {sourceGroup.months.map((month) => ( */}
                        {/*     <div className="w-full md:w-auto px-2" key={`symptom-${month}`}> */}
                        {/*       <TriggerComponent title={getLongMonthName(month)} activeItem key={`month-${month}`} /> */}
                        {/*     </div> */}
                        {/*   ))} */}
                        {/* </div> */}

                        {sourceGroup.commentPeriod && <GreyBlock text={sourceGroup.commentPeriod} />}
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {!isPreview && (
                <>
                  <div className="mt-10 md:mt-20 text-center sm:text-left sm:flex sm:items-center">
                    <Heading3 className="sm:w-3/5">{strings().test_answer.viewRecommendations}</Heading3>
                    <div className="mt-4 sm:mt-0 sm:w-2/5">
                      <Link className="no-underline" to={`/profile/results/${hash}/recommendations`}>
                        <CommonButtonDefault className="w-full" primary>
                          {strings().button.preview}
                        </CommonButtonDefault>
                      </Link>
                    </div>
                  </div>
                  <div className="mt-10 md:mt-20 text-center sm:text-left sm:flex sm:items-center">
                    <Heading3 className="sm:w-3/5">
                      {strings().test_answer.viewAllAllergens.replace(
                        '{num}',
                        `${strings().products[answer.productKey].allergens.replace(
                          '{allergensCount}',
                          answer.allergensCount,
                        )}`,
                      )}
                    </Heading3>
                    <div className="mt-4 sm:mt-0 sm:w-2/5">
                      <Link className="no-underline" to={`/profile/results/${hash}/allergens`}>
                        <CommonButtonDefault className="w-full" primary>
                          {strings().button.preview}
                        </CommonButtonDefault>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </HasBackground>
  );
}

export default ProfileResultsAllergenGroupsPageComponent;
