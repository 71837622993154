import React, { useEffect, useState } from 'react';
import { useParams, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'twin.macro';

import * as ordersApi from '../../services/api/orders';
import { FullAnswer, FullAnswerRecommendationGroup } from '../../entities/full-answer';
import HeroLg from '../../components/common/hero/heroLg';
import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import IMAGES from '../../assets/images';
import { kreipinysVardoPavardes } from '../../services/grammar/lithuanian';
import { useAuth } from '../../hooks/use-auth';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import OrderTabs from '../../components/order/tabs';
import StickyBanner from '../../components/common/sticky/stickyBanner';
import { useSite } from '../../hooks/use-site';
import { Habit } from '../../entities/foodId/habits';
import { getHabitsResults } from '../../services/api/foodId/habits';
import { EatingSymptoms } from '../../entities/foodId/eating-symptoms';
import { EatingSymptomGroup } from '../../entities/foodId/eating-symptom-group';
import { EatingIntolerances } from '../../entities/foodId/eating-intolerances';

const HasBackground = styled.div<{ theme: boolean }>`
  overflow: hidden;
  @media screen {
    ${(props) => props.theme === 'allergomedica' && `background: url(${IMAGES.BLOB.BGRESULT}) no-repeat bottom center;`}
  }
`;

interface ComponentProps {
  isPreview?: boolean;
}

function ProfileResultsPageComponent({ isPreview }: ComponentProps) {
  const { token, tokenAdmin, user } = useAuth();
  const [error, setError] = useState<string>('');
  const { hash } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [answer, setAnswer] = useState<FullAnswer>();
  const [foodIdHabits, setFoodIdHabits] = useState<Habit[]>();
  const [foodIdSymptoms, setFoodIdSymptoms] = useState<EatingSymptoms[]>();
  const [foodIdSymptomsGroups, setFoodIdSymptomsGroups] = useState<EatingSymptomGroup[]>();
  const [foodIdIntolerances, setFoodIdIntolerances] = useState<EatingIntolerances[]>();
  const { theme, isfoodId, locale } = useSite();

  let linkPrefix = `/profile/results/${hash}`;

  if (isPreview) {
    linkPrefix = `/results/preview/${hash}`;
  }

  if (locale === 'en') {
    linkPrefix = `/en${linkPrefix}`;
  }

  useEffect(() => {
    const buildRecommendationGroups = (res: FullAnswer): FullAnswerRecommendationGroup[] => {
      const groups: FullAnswerRecommendationGroup[] = [];

      res.sourceGroups
        .filter((sg) => sg.hasRecommendations)
        .forEach((sg) => {
          groups.push({
            id: sg.id,
            title: sg.customTitle || sg.sourceGroupTitle,
            recommendations: res.recommendations.filter(
              (recommendation) => recommendation.orderSourceGroupId === sg.id,
            ),
          });
        });

      const unassignedRecommendations = res.recommendations.filter(
        (recommendation) => !recommendation.orderSourceGroupId && !recommendation.isAction,
      );

      if (unassignedRecommendations.length > 0) {
        groups.push({
          id: -1,
          title: strings().recommendations.otherRecommendations,
          recommendations: unassignedRecommendations,
        });
      }

      return groups;
    };

    const fetchAnswer = async () => {
      if (hash) {
        try {
          const res = await ordersApi.getResult(token || tokenAdmin, hash, locale === 'en' ? locale : undefined);

          res.recommendationGroups = buildRecommendationGroups(res);

          res.sourceGroups = res.sourceGroups.map((sourceGroup) => {
            return {
              ...sourceGroup,
              secondarySources: sourceGroup.secondarySources.filter((source) => source.strength > 0),
              allergens: sourceGroup.allergens.filter((allergen) => allergen.value > 0),
            };
          });
          // console.log('setAnswer', res);
          setAnswer(res);

          if (res.productKey === 'CONSULTATION' && location.pathname.indexOf('recommendations') === -1) {
            navigate(`${linkPrefix}/recommendations`);
          }
        } catch (e) {
          setError((e as Error).message);
        }
      }
    };

    fetchAnswer();
  }, [hash, token, tokenAdmin]);

  useEffect(() => {
    if (isfoodId) {
      const fetchHabits = async () => {
        if (hash) {
          const orderHabits = await getHabitsResults(token || tokenAdmin, hash, locale === 'en' ? locale : undefined);

          setFoodIdHabits(orderHabits.habits);
          setFoodIdSymptoms(orderHabits.symptoms);
          setFoodIdSymptomsGroups(orderHabits.symptomGroups);
          setFoodIdIntolerances(orderHabits.intolerances);

          // console.log('fetchHabits', orderHabits);
        }
      };

      fetchHabits();
    }
  }, [hash, token, tokenAdmin, isfoodId]);

  let tabs;

  const tabsAllergomedica = [
    { name: strings().test_answer.navigation.allergyProfile, href: linkPrefix, current: true },
    {
      name: strings().test_answer.navigation.recommendations,
      href: `${linkPrefix}/recommendations`,
      current: false,
    },
    { name: strings().test_answer.navigation.allergens, href: `${linkPrefix}/allergens`, current: false },
  ];

  const tabsFoodId = [
    { name: strings().FOOD_ID.test_answer.navigation.allergyProfile, href: linkPrefix, current: true },
    {
      name: strings().FOOD_ID.test_answer.navigation.recommendations,
      href: `${linkPrefix}/recommendations`,
      current: false,
    },
    { name: strings().FOOD_ID.test_answer.navigation.allergens, href: `${linkPrefix}/allergens`, current: false },
  ];

  switch (theme) {
    case 'foodId':
      tabs = tabsFoodId;
      break;
    default:
      tabs = tabsAllergomedica;
  }

  for (const tab of tabs) {
    tab.current = tab.href === location.pathname;
  }

  return (
    <HasBackground theme={theme}>
      {error && (
        <div className="container max-w-4xl px-4 mx-auto pb-10 mt-16">
          <AlertPrimary text={error} type="danger" />
        </div>
      )}
      {!answer && !error && <CommonAnimatedLoader />}
      {answer && !answer.isReady && !isPreview && (
        <div className="container max-w-4xl px-4 mx-auto pb-20 mt-16">
          <HeroLg
            isResult
            title={`${strings().test_answer.hello} ${kreipinysVardoPavardes(answer.name)}`}
            text={strings().test_answer.yourAnswerIsNotReady}
          />
        </div>
      )}
      {answer && (answer.isReady || isPreview) && (
        <>
          {answer.productKey !== 'CONSULTATION' && <OrderTabs tabs={tabs} pdfId={answer.pdfId} />}
          <Outlet
            context={{
              answer,
              isPreview,
              setAnswer,
              foodIdHabits,
              foodIdSymptoms,
              foodIdSymptomsGroups,
              foodIdIntolerances,
            }}
          />
        </>
      )}
      <StickyBanner providedFeedback={user?.providedFeedback} hasCompletedOrders={user?.hasCompletedOrders} />
    </HasBackground>
  );
}

ProfileResultsPageComponent.defaultProps = {
  isPreview: false,
};

export default ProfileResultsPageComponent;
