import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { up } from 'styled-breakpoints';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

import { ShortAnswer, ShortAnswerEvaluation, ShortAnswerItem, ShortAnswerProduct } from '../../entities/short-answer';
import * as questionnaireApi from '../../services/api/questionnaire';
import CommonButtonDefault from '../../components/common/buttons/default';
import { strings } from '../../localization/strings';
import IMAGES from '../../assets/images';
import Heading3 from '../../components/common/headings/heading3';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import QuestionnaireProductsListItem from '../../components/questionnaire/products-list-item';
import Heading2 from '../../components/common/headings/heading2';
import { kreipinysVardoPavardes } from '../../services/grammar/lithuanian';
import QuestionnaireProductsListItemHorizontal from '../../components/questionnaire/products-list-item-horizontal';
import { capitalizeFirstLetter } from '../../services/formatter/text';

const ContainerLg = styled.div`
  ${tw`mx-auto container px-4`}
  ${up('lg')} {
    max-width: 842px;
  }
`;

const PurchaseBlockContainer = styled.div`
  position: relative;
  font-size: 16px;
  margin-top: 2rem;
`;

const Hero = styled.div`
  text-align: center;
  padding-top: 5rem;
  position: relative;
  background: url(${IMAGES.BLOB.SECOND}) no-repeat top right;
  background-size: 240px auto;
  padding-bottom: 4rem;

  ${up('md')} {
    padding-top: 8rem;
    background-size: 400px auto;
  }
`;

const Probability = styled.div<{
  active?: boolean;
  state?: string;
}>`
  ${tw`rounded bg-success-500 text-success-700 text-[11px] md:text-sm h-[32px] py-2.5 font-bold w-1/5`}
  ${(props) => props.active && tw`h-[40px] min-w-[84px]`}
  ${(props) => props.state === 'low' && tw`bg-senseLow-100 text-senseLow-600`}
  ${(props) => props.state === 'medium' && tw`bg-senseMedium-100 text-senseMedium-600`}
  ${(props) => props.state === 'high' && tw`bg-senseHigh-100 text-senseHigh-400`}
  ${(props) => props.state === 'veryHigh' && tw`bg-senseHigh-200 text-senseHigh-600`}
`;

const Rate = styled.div<{
  usual?: boolean;
  rare?: boolean;
}>`
  ${tw`h-[32px] flex justify-center items-center bg-symptoms-500 rounded text-white text-xs font-bold`}
  ${(props) => props.usual && tw`bg-symptoms-400 text-senseHigh-400 text-blue-800 `}
  ${(props) => props.rare && tw`bg-blue-600 text-blue-800`}
`;

const DurationMarker = styled.div<{
  month?: boolean;
  year?: boolean;
  moreThanYear?: boolean;
  moreThanFive?: boolean;
}>`
  ${tw`h-[18px] w-[18px] bg-gradient-to-r from-blue-600 to-blue-500 rounded-[18px]`}
  ${(props) => props.month && tw`w-2/12`}
  ${(props) => props.year && tw`w-2/5`}
  ${(props) => props.moreThanYear && tw`w-3/5`}
  ${(props) => props.moreThanFive && tw`w-full`}
`;

const BlockWShadow = styled.div`
  ${tw`shadow-lg px-5 py-6 bg-white rounded-2xl my-7 relative z-10`}
`;

const Hint = styled.div`
  ${tw`text-xs md:text-base`}
`;

export default function QuestionnaireAnswerPageComponent() {
  const { hash } = useParams<{ hash: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [answer, setAnswer] = useState<ShortAnswer>();
  const [answerEvaluation, setAnswerEvaluation] = useState<ShortAnswerEvaluation>();
  const [recommendedProduct, setRecommendedProduct] = useState<ShortAnswerProduct>();
  const [products, setProducts] = useState<ShortAnswerProduct[]>();
  const [duration, setDuration] = useState<ShortAnswerItem>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnswer = async (h: string) => {
      const res = await questionnaireApi.answer(h);

      setAnswer(res);

      // isRecommended

      // const bestProduct = res.product || res.products.find((p) => p.recommended) || res.products[2];

      // setTiers([
      //   {
      //     name: strings().features.ourClinic,
      //     priceMonthly: `${strings().upTo} ${currency(bestProduct.price)}`,
      //   },
      //   {
      //     name: strings().features.privateClinic,
      //     priceMonthly: `${strings().startingFrom} ${currency(bestProduct.priceOthers)}`,
      //   },
      // ]);
      //
      // setSections([
      //   {
      //     name: 'Features',
      //     features: allFeatures.filter((feature) => feature.plans.indexOf(bestProduct?.key) !== -1),
      //   },
      // ]);

      // setRecommendedProduct(bestProduct);
      // setIsConsultation(bestProduct.id === parameters().product.consultationId);
      setIsLoading(false);
    };

    if (hash) {
      fetchAnswer(hash);
    }
  }, [hash]);

  useEffect(() => {
    const fetchAnswerEvaluation = async (h: string) => {
      const res = await questionnaireApi.answerEvaluation(h);

      setAnswerEvaluation(res);
      const bestProduct = res.products.find((p) => p.recommended) || res.products[12];

      setRecommendedProduct(bestProduct);

      const productsList = res.products.filter((element) => {
        return element.key === 'FULL' || element.key === 'SCREENER_ASIT' || element.key === 'CONSULTATION';
      });

      setProducts(productsList);

      setDuration(res.durations[0]);
      setIsLoading(false);
    };

    if (hash) {
      fetchAnswerEvaluation(hash);
    }
  }, [hash]);

  const onSelectProduct = async (product: ShortAnswerProduct) => {
    setIsLoading(true);
    if (hash) {
      await questionnaireApi.selectProduct(hash, product.id);

      navigate(`/questionnaire/onboarding/${product.id}/2`);
    }
  };

  return (
    <div>
      {isLoading && !answerEvaluation && <CommonAnimatedLoader />}
      {!isLoading && hash && answer && answerEvaluation && (
        <PurchaseBlockContainer className="overflow-hidden">
          <>
            <ContainerLg>
              <Hero>
                <h1 className="md:pb-4 text-3xl sm:text-4xl md:text-5xl leading-tight mb-5 relative z-10 print:!text-lg">
                  {/* {answer.product ? strings().products[answer.product.key].orderTitle : strings().product.title} */}
                  <b>
                    {strings().shortAnswer.hello} {capitalizeFirstLetter(kreipinysVardoPavardes(answer.customerName))}
                  </b>{' '}
                  <br />
                  {strings().shortAnswer.title}
                </h1>
                <div className="md:w-4/5 mx-auto leading-snug">
                  {/* {answer.personalised.join(' ')} */}
                  <div className="mt-6 text-gray-500 text-base">{strings().shortAnswer.description}</div>
                </div>
              </Hero>
              <div className="text-center pb-10">
                <Heading2 className="w-4/5 mx-auto">{strings().shortAnswer.possibility.title}</Heading2>
                <div className="flex items-center space-x-1 mb-4">
                  <Probability active={answerEvaluation?.probability === 1}>
                    <span className={`${answerEvaluation?.probability !== 1 && 'hidden'}`}>
                      {strings().shortAnswer.possibility.items.veryLow}
                    </span>
                  </Probability>
                  <Probability state="low" active={answerEvaluation?.probability === 2}>
                    <span className={`${answerEvaluation?.probability !== 2 && 'hidden'}`}>
                      {strings().shortAnswer.possibility.items.low}
                    </span>
                  </Probability>
                  <Probability state="medium" active={answerEvaluation?.probability === 3}>
                    <span className={`${answerEvaluation?.probability !== 3 && 'hidden'}`}>
                      {strings().shortAnswer.possibility.items.medium}
                    </span>
                  </Probability>
                  <Probability state="high" active={answerEvaluation?.probability === 4}>
                    <span className={`${answerEvaluation?.probability !== 4 && 'hidden'}`}>
                      {strings().shortAnswer.possibility.items.high}
                    </span>
                  </Probability>
                  <Probability state="veryHigh" active={answerEvaluation?.probability === 5}>
                    <span className={`${answerEvaluation?.probability !== 5 && 'hidden'}`}>
                      {strings().shortAnswer.possibility.items.veryHigh}
                    </span>
                  </Probability>
                </div>
                <div className="mt-6 text-gray-500">
                  <div className="md:w-4/5 mx-auto">{strings().shortAnswer.possibility.hint}</div>
                </div>
              </div>
              <div className="relative">
                <img
                  className="absolute left-[-100px] lg:left-[-150px] top-[-60px] lg:top-[-40px] z-0 h-[250px] lg:h-[300px] w-auto"
                  src={IMAGES.BLOB.FIRST}
                  alt=""
                />
                <BlockWShadow>
                  <Heading3 className="text-blue-500 mb-7">{strings().shortAnswer.symptoms.title}</Heading3>
                  <div className="flex space-x-7 mb-4">
                    <div className="w-[78px]">
                      <Rate>{strings().shortAnswer.symptoms.items.often}</Rate>
                    </div>
                    {answerEvaluation?.frequentSymptoms && answerEvaluation.frequentSymptoms.length ? (
                      <div className="text-xs md:text-base">
                        <ul className="list-disc pl-3">
                          {answerEvaluation.frequentSymptoms.map((symptom, i) => (
                            <li key={`frequent-${i + 1}`}>{symptom.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="text-xs md:text-base text-gray-400">
                        {strings().shortAnswer.symptoms.noSymptomsIndicated}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center space-x-7 mb-4">
                    <div className="w-[78px]">
                      <Rate usual>{strings().shortAnswer.symptoms.items.common}</Rate>
                    </div>
                    {answerEvaluation?.mediocreSymptoms && answerEvaluation.mediocreSymptoms.length ? (
                      <div className="text-xs md:text-base">
                        <ul className="list-disc pl-3">
                          {answerEvaluation.mediocreSymptoms.map((symptom, i) => (
                            <li key={`mediocre-${i + 1}`}>{symptom.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="text-xs md:text-base text-gray-400">
                        {strings().shortAnswer.symptoms.noSymptomsIndicated}
                      </div>
                    )}
                  </div>
                  <div className="flex items-center space-x-7 mb-4">
                    <div className="w-[78px]">
                      <Rate rare>{strings().shortAnswer.symptoms.items.rare}</Rate>
                    </div>

                    {answerEvaluation?.rareSymptoms && answerEvaluation.rareSymptoms.length > 0 ? (
                      <div className="text-xs md:text-base">
                        <ul className="list-disc pl-3">
                          {answerEvaluation.rareSymptoms.map((symptom, i) => (
                            <li key={`rare-${i + 1}`}>{symptom.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="text-xs md:text-base text-gray-400">
                        {strings().shortAnswer.symptoms.noSymptomsIndicated}
                      </div>
                    )}
                  </div>
                  <div className="mt-6 text-gray-500 flex space-x-2">
                    <div>
                      <ExclamationCircleIcon className="w-5 h-5 text-gray-400 flex-shrink-0" aria-hidden="true" />
                    </div>
                    <Hint>{strings().shortAnswer.symptoms.hint}</Hint>
                  </div>
                </BlockWShadow>
              </div>
              <BlockWShadow>
                <Heading3 className="text-blue-500 mb-4">{strings().shortAnswer.periodicity.title}</Heading3>
                {answerEvaluation?.times && answerEvaluation.times.length ? (
                  <ul className="list-disc pl-4">
                    {answerEvaluation?.times.map((time) => (
                      <li key={time.title}>{time.title}</li>
                    ))}
                  </ul>
                ) : (
                  <div className="text-xs md:text-base text-gray-400">{strings().shortAnswer.symptoms.noDetails}</div>
                )}
                <div className="mt-6 text-gray-500 flex space-x-2">
                  <div>
                    <ExclamationCircleIcon className="w-5 h-5 text-gray-400 flex-shrink-0" aria-hidden="true" />
                  </div>
                  <Hint>{strings().shortAnswer.periodicity.hint}</Hint>
                </div>
              </BlockWShadow>
              <div className="relative">
                <img
                  className="absolute right-[-100px] lg:right-[-200px] top-[-120px] z-0 h-[250px] lg:h-[300px] w-auto"
                  src={IMAGES.BLOB.FIRST}
                  alt=""
                />
                <BlockWShadow>
                  <Heading3 className="text-blue-500 mb-4"> {strings().shortAnswer.circumstances.title}</Heading3>

                  {answerEvaluation?.circumstances && answerEvaluation.circumstances.length ? (
                    <ul className="list-disc pl-4">
                      {answerEvaluation?.circumstances.map((circumstance) => (
                        <li key={circumstance.title}>{circumstance.title}</li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-xs md:text-base text-gray-400">{strings().shortAnswer.symptoms.noDetails}</div>
                  )}
                  <div className="mt-6 text-gray-500 flex space-x-2">
                    <div>
                      <ExclamationCircleIcon className="w-5 h-5 text-gray-400 flex-shrink-0" aria-hidden="true" />
                    </div>
                    <Hint>{strings().shortAnswer.circumstances.hint}</Hint>
                  </div>
                </BlockWShadow>
              </div>
              <BlockWShadow>
                <Heading3 className="text-blue-500 mb-4">{strings().shortAnswer.duration.title}</Heading3>
                {answerEvaluation?.durations && answerEvaluation.durations.length ? (
                  <div className="sm:w-[300px]">
                    <div className={`${!duration?.factorId ? 'px-0' : 'px-2'} relative`}>
                      <div className="w-full h-[2px] bg-blue-500 absolute left-0 top-[50%] z-0" />
                      <DurationMarker
                        month={duration?.title === 'Kelis mėnesius'}
                        year={duration?.factorId === 1}
                        moreThanYear={duration?.factorId === 2}
                        moreThanFive={duration?.factorId === 3}
                        className="relative z-10"
                      />
                    </div>
                    <div className="flex justify-between text-xs mt-2">
                      <div>{strings().shortAnswer.duration.items.justNow}</div>
                      <div>{strings().shortAnswer.duration.items.fiveYear}</div>
                    </div>
                  </div>
                ) : (
                  <div className="text-xs md:text-base text-gray-400">{strings().shortAnswer.symptoms.noDetails}</div>
                )}

                <div className="mt-6 text-gray-500 flex space-x-2">
                  <div>
                    <ExclamationCircleIcon className="w-5 h-5 text-gray-400 flex-shrink-0" aria-hidden="true" />
                  </div>
                  <Hint>{strings().shortAnswer.duration.hint}</Hint>
                </div>
              </BlockWShadow>
              <div className="relative mb-12 mt-20 pt-10">
                <img
                  className="absolute left-[100px] top-[-10px] z-0 h-[250px] w-auto"
                  src={IMAGES.BLOB.FIRST}
                  alt=""
                />
                <div className="text-center mb-7 relative z-10">
                  <Heading3 className="mb-4">{strings().shortAnswer.allergens.title}</Heading3>
                  <div className="text-gray-500">
                    {answerEvaluation?.sources.length > 0
                      ? strings().shortAnswer.allergens.text
                      : strings().shortAnswer.allergens.textNoAllergens}
                  </div>
                </div>

                {answerEvaluation?.sources &&
                  answerEvaluation?.sources.map((source, index) => (
                    <div className="flex space-x-3 items-center" key={`sg-${index + 1}`}>
                      <div className="w-[28px] text-[22px] text-blue-500 font-bold">{index + 1}</div>
                      <div className="relative w-full z-10 flex items-center bg-symptoms-200 rounded-[20px] px-4 py-3 space-x-4 drop-shadow-[0_4px_10px_rgba(0,0,0,0.07)] mb-3">
                        {source.iconName && (
                          <div className="h-10 w-10 text-4xl rounded-full justify-center inline-flex items-center bg-blue-500 flex-shrink-0 flex-grow-0 text-white ">
                            <span className={`block icon am-icon-${source.iconName}`} />
                          </div>
                        )}
                        <div className="font-bold text-blue-500">{source.sourceTitle}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </ContainerLg>
            <div className="px-4 sm:px-6 lg:px-8 my-4 md:my-20">
              <div className="max-w-2xl mx-auto mb-10 text-center">
                <Heading2>{strings().shortAnswer.moreBlock.title}</Heading2>

                <div className="text-gray-500 z-10 relative">
                  {answerEvaluation?.probability === 1 && strings().shortAnswer.moreBlock.items.low}
                  {answerEvaluation?.probability === 2 && strings().shortAnswer.moreBlock.items.high}
                  {answerEvaluation?.probability === 3 && strings().shortAnswer.moreBlock.items.high}
                  {answerEvaluation?.probability === 4 && strings().shortAnswer.moreBlock.items.veryHigh}
                  {answerEvaluation?.probability === 5 && strings().shortAnswer.moreBlock.items.veryHigh}
                </div>
              </div>
              <div className="max-w-4xl mx-auto px-0 sm:px-6 lg:px-12 space-y-12 lg:space-y-0">
                {recommendedProduct && (
                  <div>
                    <QuestionnaireProductsListItem
                      product={recommendedProduct}
                      key={`${recommendedProduct?.key}`}
                      isRecommended={recommendedProduct?.recommended}
                    >
                      {recommendedProduct.comingSoon ? (
                        <div className="w-full text-center py-4 text-gray-500">{strings().product.comingSoon}</div>
                      ) : (
                        <CommonButtonDefault
                          primary
                          className="w-full mt-8"
                          onClick={() => onSelectProduct(recommendedProduct)}
                        >
                          {strings().product.select}
                        </CommonButtonDefault>
                      )}
                    </QuestionnaireProductsListItem>
                  </div>
                )}
                <div className="mt-5">
                  {products &&
                    products.map(
                      (product, index) =>
                        !product.recommended && (
                          <QuestionnaireProductsListItemHorizontal product={product} key={`p-${index + 1}`}>
                            {product.comingSoon ? (
                              <div className="w-full text-center py-4 text-gray-500">
                                {strings().product.comingSoon}
                              </div>
                            ) : (
                              <CommonButtonDefault
                                className="w-full mt-3 md:mt-0 md:w-60"
                                primary
                                onClick={() => onSelectProduct(product)}
                              >
                                {strings().product.select}
                              </CommonButtonDefault>
                            )}
                          </QuestionnaireProductsListItemHorizontal>
                        ),
                    )}
                </div>
              </div>
            </div>
          </>
        </PurchaseBlockContainer>
      )}
    </div>
  );
}
