import React from 'react';
import tw, { styled } from 'twin.macro';
import { down, up } from 'styled-breakpoints';

import { useSite } from '../../../hooks/use-site';

interface ComponentProps {
  name: string;
  position: string;
  text: string;
  image?: string | undefined;
  signature?: string | undefined;
}

const Image = styled.img`
  max-width: 100%;
  height: 60px;
  width: auto;
  ${tw`mb-3 md:mb-0 rounded-full`};

  ${up('md')} {
    height: 88px;
  }
`;

const Signature = styled.img`
  max-width: 150px;
  ${tw`mb-3 md:mb-0`};

  ${down('sm')} {
    height: 40px;
    margin-left: auto;
  }
`;

function DoctorBlock({ name, position, text, image, signature }: ComponentProps) {
  const { hasDoctor } = useSite();

  return (
    <div className="bg-white border-content-500 border rounded-lg md:rounded-2xl p-3 md:px-6 md:py-5 print:border-0 print:!p-0">
      {hasDoctor && (
        <div className="flex flex-1 flex-wrap items-center">
          {image && (
            <div className="pr-5 md:pr-7">
              <Image src={image} alt={name} />
            </div>
          )}

          <div className="pr-5 md:pr-7">
            {name !== 'null null' && <div className="font-bold">{name}</div>}
            <div className="">{position}</div>
          </div>
          {!!signature && (
            <div>
              <Signature src={signature} alt={name} />
            </div>
          )}
        </div>
      )}
      {/* eslint-disable react/no-danger */}
      {!!text.length && (
        <div className="mt-5 md:mt-7 print:!mt-1 print:!text-base" dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </div>
  );
}

DoctorBlock.defaultProps = {
  image: undefined,
  signature: undefined,
};

export default DoctorBlock;
