import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

import { strings } from '../../../../localization/strings';
import LegendBlock from '../../../../components/questionnaire/results/legend';
import FoodGroup from '../../../../components/questionnaire/results/food-group';
import { allWords, capitalizeFirstLetter } from '../../../../services/formatter/text';
import { genitive } from '../../../../services/grammar/lithuanian/singular';
import Heading2 from '../../../../components/common/headings/heading2';
import Heading3 from '../../../../components/common/headings/heading3';
import Allergen from '../../../../components/questionnaire/results/allergen';
import { FullAnswer, ImportantSources } from '../../../../entities/full-answer';
import { EatingSymptomGroup } from '../../../../entities/foodId/eating-symptom-group';
import HeadingResult from '../../../../components/profile/heading-results';
import HeadingResultHighlight from '../../../../components/profile/heading-result-highlight';
import { Habit } from '../../../../entities/foodId/habits';
import { EatingSymptoms } from '../../../../entities/foodId/eating-symptoms';
import SourceGroupHeading from '../../../../components/profile/source-group-heading';
import SourceGroupIcon from '../../../../components/profile/source-group-icon';
import { kreipinysVardoPavardes } from '../../../../services/grammar/lithuanian';
import { pluralize, pluralizeWithNumber } from '../../../../services/grammar/pluralize';
import { useSite } from '../../../../hooks/use-site';
import CommonButtonDefault from '../../../../components/common/buttons/default';

interface ComponentProps {
  answer?: FullAnswer;
  foodIdSymptomsGroups?: EatingSymptomGroup[];
  foodIdSymptoms?: EatingSymptoms[];
  habits?: Habit[];
}

const StyledSummary = styled.div`
  ${tw`bg-electric min-w-[160px] px-3 py-4 md:px-4 md:py-4 rounded-2xl leading-snug`}
`;

const Icon = styled.div`
  ${tw`h-8 w-8 flex-shrink-0 text-2xl rounded-full text-white justify-center inline-flex items-center print:hidden text-white bg-blue-800`}
`;

const StrengthCol = styled.div`
  ${tw`h-full w-1/3 z-20 border-r-2 border-white`}
`;

const StrengthIntensity = styled.div<{ intensity: number }>`
  ${tw`absolute left-0 top-0 h-full z-10 rounded-l-full`}
  ${(props) => props.intensity === 5 && tw`w-full bg-intensityVeryHigh rounded-r-full`}
    ${(props) => props.intensity === 4 && tw`w-4/5 bg-intensityHigh`}
    ${(props) => props.intensity === 3 && tw`w-3/5 bg-intensityMedium`}
    ${(props) => props.intensity === 2 && tw`w-2/5 bg-intensityLow`}
    ${(props) => props.intensity === 1 && tw`w-1/5 bg-success-50`}
`;

const Checkmark = styled.input`
  ${tw`h-3 w-3 md:h-4 md:w-4 print:!h-3 print:!w-3 text-content-800 border-content-500 rounded-full flex-shrink-0`}

  &:checked, &:checked:hover {
    ${tw`bg-content-800`}
  }
`;

const CheckmarkPlaceholder = styled.div`
  ${tw`h-3 w-3 md:h-4 md:w-4 rounded-full border border-content-600 flex-shrink-0 print:!h-3 print:!w-3`}
`;

function ProfileResultsFoodIdPreviewPageComponent({
  answer,
  foodIdSymptomsGroups,
  habits,
  foodIdSymptoms,
}: ComponentProps) {
  const { locale } = useSite();
  const [importantSources, setImportantSources] = useState<ImportantSources[]>();
  const [toggleItems, setToggleItems] = useState<boolean>(false);

  useEffect(() => {
    setImportantSources(answer?.importantSources?.slice(0, 10));
  }, [answer, answer?.importantSources]);
  const onClickShowAll = () => {
    setToggleItems(!toggleItems);

    if (!toggleItems) {
      setImportantSources(answer?.importantSources);
    } else {
      setImportantSources(answer?.importantSources?.slice(0, 10));
    }
  };

  return (
    <>
      <div className="bg-primary py-10 md:py-14 print:py-0">
        <div className="container max-w-4xl px-4 mx-auto print:px-0">
          <div className="text-white print:!text-black text-2xl sm:text-4xl lg:text-5xl print:!text-lg leading-snug text-center font-bold">
            <div>
              {strings().FOOD_ID.test_answer.hello}{' '}
              {answer && answer.name && (
                <span>{locale === 'pl' ? answer.name : kreipinysVardoPavardes(answer.name)}.</span>
              )}
            </div>
            <div className="text-2xl sm:text-4xl print:!text-lg mt-4">
              {strings().FOOD_ID.test_answer.hero_subtitle}
            </div>
          </div>

          <div className="mt-5 md:mt-12 p-4 md:px-5 lg:px-7 md:py-7 rounded-lg md:rounded-xl bg-white drop-shadow-xl print:drop-shadow-none print:rounded-none print:!p-0">
            <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-3 md:space-x-10 justify-between mb-7 print:!text-md">
              <StyledSummary>
                <div className="font-bold text-lg leading-snug">
                  {answer?.weightValue} {strings().FOOD_ID.test_answer.summary.kg}
                </div>
                <div>{strings().FOOD_ID.test_answer.summary.weight}</div>
              </StyledSummary>
              <StyledSummary>
                <div className="font-bold text-lg leading-snug">
                  {answer?.heightValue ? answer.heightValue : '-'} {strings().FOOD_ID.test_answer.summary.cm}
                </div>
                <div>{strings().FOOD_ID.test_answer.summary.height}</div>
              </StyledSummary>
              <StyledSummary>
                <div className="font-bold text-lg leading-snug">
                  {strings().FOOD_ID.test_answer.summary.BMI} {answer?.bmi ? answer.bmi : '-'}{' '}
                </div>
                <div>{strings().FOOD_ID.test_answer.summary.bmi}</div>
              </StyledSummary>
            </div>
            <div className="text-xl font-bold mb-4">{strings().FOOD_ID.test_answer.yourSymptoms}</div>

            {foodIdSymptomsGroups &&
              foodIdSymptomsGroups.map((group, index) => (
                <div className="mb-7 print:mb-3" key={`food-symptom-group-${group.symptomGroupId}${index + 1}`}>
                  <div className="flex space-x-3 items-center">
                    <Icon aria-hidden="true">
                      <span className={`icon am-icon-${group.iconName}`} />
                      {/* <span className={`icon am-icon-${iconName}`} /> */}
                    </Icon>
                    <div className="font-bold">{group.symptomGroupTitle}</div>
                  </div>
                  {group.symptoms && (
                    <div className="mb-3 print:shadow-none shadow-md px-3 py-4 rounded-lg print:rounded-none print:!p-2">
                      {group.symptoms.map((symptom, key) => (
                        <div className="py-2" key={`food-symptom-${key + 1}`}>
                          <div className="flex items-center justify-between">
                            <div>{symptom.symptomTitle}</div>
                            {symptom.strength && (
                              <div>
                                <div className="flex items-center text-content-700 space-x-2">
                                  <div>
                                    <div className="w-[70px] h-4 !rounded-full z-1 !overflow-hidden bg-content-300 relative">
                                      <div className="w-full flex h-full z-20">
                                        <StrengthCol />
                                        <StrengthCol />
                                        <StrengthCol />
                                        <StrengthCol />
                                        <StrengthCol className="!border-none" />
                                      </div>
                                      <StrengthIntensity intensity={Number(symptom.strength)} />
                                    </div>
                                  </div>
                                  <div className="text-xs">
                                    <span className="text-content-900 font-bold">{Number(symptom.strength)}</span> / 5
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="container max-w-4xl px-4 mx-auto pb-10 print:max-w-none">
        {answer && (
          <div className="pt-7 print:pt-5">
            <HeadingResult fontSize="lg">
              <HeadingResultHighlight>
                {answer.importantSources && answer.importantSources.length > 0 ? (
                  <>
                    {strings().FOOD_ID.test_answer.youAreSensitive}{' '}
                    {pluralizeWithNumber(
                      answer.importantSources.length,
                      strings().FOOD_ID.test_answer.allergenGroupPlural,
                      locale,
                    )}
                  </>
                ) : (
                  strings().FOOD_ID.test_answer.noSensitivity
                )}
              </HeadingResultHighlight>
            </HeadingResult>

            <div className="mb-10 mt-10 print:hidden">
              <LegendBlock hasNumValue={false} />
            </div>
            {/* food list */}
            {importantSources && (
              <>
                <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-wrap text-xs sm:space-x-3">
                  {importantSources?.map((item, sourceGroupIdx) => (
                    <div key={`food-item-${item.id}-${sourceGroupIdx + 1}`}>
                      <FoodGroup title={item.sourceTitle} strength={item.strength} iconName={item.iconName} />
                    </div>
                  ))}
                </div>
                {answer.importantSources && answer.importantSources.length > 10 && (
                  <div className="flex justify-center mt-5">
                    <CommonButtonDefault primary onClick={onClickShowAll}>
                      {toggleItems ? strings().FOOD_ID.button.showLess : strings().FOOD_ID.button.showAll}
                    </CommonButtonDefault>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        <div className="pt-7 md:pt-12 print:pt-5">
          <HeadingResult fontSize="lg">
            <HeadingResultHighlight>
              {habits &&
                (locale === 'lt' ? (
                  <>
                    {pluralize(habits.length, strings().FOOD_ID.test_answer.habits.youAreSensitivePlural, locale)}{' '}
                    {pluralizeWithNumber(habits.length, strings().FOOD_ID.test_answer.habits.habitsPlural, locale)}
                    {strings().FOOD_ID.test_answer.habits.canProvokeSymptoms}
                  </>
                ) : (
                  strings().FOOD_ID.test_answer.habits.youAreSensitiveStaticTitle
                ))}{' '}
              {!habits && strings().FOOD_ID.test_answer.habits.noHabits}
            </HeadingResultHighlight>
          </HeadingResult>
          <div className="grid md:grid-cols-2 gap-6 mt-10 md:mt-12 print:mt-3 print:gap-1">
            {habits &&
              habits.map((habit, index) => (
                <div
                  key={`habit-${habit.habitTitle}-${index + 1}`}
                  className="bg-content-300 rounded-xl py-5 print:py-2 print:px-1 px-4 md:px-7 lg:py-5"
                >
                  <h4 className="text-lg font-bold mb-1">{habit.habitTitle}</h4>
                  <div className="print:text-md">
                    <span>
                      {habit.isPolishPlural
                        ? strings().FOOD_ID.test_answer.habits.canProvokeYouPlural
                        : strings().FOOD_ID.test_answer.habits.canProvokeYou}
                    </span>
                    :
                    <ul className="list-disc pl-5">
                      {habit.symptomGroups &&
                        habit.symptomGroups.map((symptom, key) => (
                          <li key={`food-symptoms-list-${key + 1}`}>
                            {locale === 'lt' ? (
                              <span>
                                {symptom.symptomGroupTitle === 'Neurologiniai sutrikimai'
                                  ? 'neurologinių sutrikimų'
                                  : allWords(symptom.symptomGroupTitle.toLowerCase(), genitive)}
                              </span>
                            ) : (
                              symptom.symptomGroupTitle.toLowerCase()
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="pt-7 md:pt-12 print:pt-5">
          <HeadingResult fontSize="lg">
            <HeadingResultHighlight>{strings().FOOD_ID.test_answer.symptoms.title}</HeadingResultHighlight>
          </HeadingResult>

          {foodIdSymptoms &&
            foodIdSymptoms.map((symptom, idx) => (
              <div
                className="bg-white rounded-lg md:rounded-xl overflow-hidden block-shadow mt-8 md:mt-12 print:!mt-3 print:!mb-5 print:!pt-3 print:border-t print:border-black print:!rounded-none"
                key={`symptom-group-${symptom.symptomId}-${idx + 1}`}
              >
                <SourceGroupHeading strength={symptom.strength}>
                  <SourceGroupIcon strength={symptom.strength} size="md">
                    <span className={`text-2xl icon am-icon-${symptom.iconName}`} />
                  </SourceGroupIcon>

                  <div className="flex flex-1 flex-col print:flex-row print:justify-start justify-center">
                    <Heading2 className="!mb-0 md:mb-2 print:!mb-1 print:mr-1">{symptom.symptomTitle}</Heading2>
                  </div>
                </SourceGroupHeading>
                <div className="p-5 md:p-10 print:!p-1">
                  <div>{symptom.symptomDescription}</div>
                  {symptom.allHabits && (
                    <div className={`${symptom.symptomDescription && 'pt-5 md:pt-11 print:!pt-3'}`}>
                      <Heading3 className="mb-5">
                        {strings().FOOD_ID.test_answer.symptoms.titleHabitsThatCanProvokeSymptom}
                      </Heading3>

                      <div className="print:mb-1 grid grid-cols-2 mb-3">
                        {symptom.allHabits.map((habit, key) => (
                          <div key={`habit-${habit.habitId}-${key + 1}`}>
                            <div className="mb-3 flex items-center print:mb-1">
                              {/* <div */}
                              {/*   className={`w-[13px] h-[13px] rounded-full flex-shrink-0 mr-2 ${ */}
                              {/*     symptom.selectedHabits.includes(habit.habitId) && 'bg-content-500' */}
                              {/*   }`} */}
                              {/* /> */}
                              {symptom.selectedHabits.includes(habit.habitId) ? (
                                <Checkmark
                                  id="comments"
                                  aria-describedby="comments-description"
                                  name="comments"
                                  type="checkbox"
                                  checked
                                  readOnly
                                />
                              ) : (
                                <CheckmarkPlaceholder />
                              )}

                              <div className={`ml-2 ${symptom.selectedHabits.includes(habit.habitId) && 'font-bold'} `}>
                                {habit.habitTitle}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {symptom?.sources && symptom?.sources.length > 0 && (
                    <div className="pt-5 md:pt-7 print:!pt-5">
                      <Heading3 className="mb-5">
                        {strings().FOOD_ID.test_answer.symptoms.titleFoodThatCanProvokeSymptom}
                      </Heading3>
                      <div className="flex flex-1 flex-wrap -mx-2 print:!pt-2">
                        {symptom?.sources.map((secondarySource, key) => (
                          <div className="px-2 mb-2" key={`food-${secondarySource.sourceTitle}-${key + 1}`}>
                            <Allergen
                              title={secondarySource.sourceTitle}
                              strength={0}
                              iconName={secondarySource.sourceIconName}
                              // strength={secondarySource.strength}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {symptom.factors && symptom.factors.length > 0 && (
                    <div className="pt-5 md:pt-7 print:!pt-5">
                      <Heading3 className="mb-5">{strings().FOOD_ID.test_answer.symptoms.titleOtherFactors}</Heading3>
                      <ul className="list-inside list-disc">
                        {symptom.factors.map((factor, key) => (
                          <li className="mb-2" key={`${symptom.symptomId}-factor-${key + 1}`}>
                            {factor.items && (
                              <>
                                {factor.items.map((item, index) => (
                                  <span key={`${symptom.symptomId}-factor-${index + 1}-item`}>
                                    {index === 0 ? capitalizeFirstLetter(item.title) : item.title}
                                    {factor.items.length > index + 1 && <>, </>}
                                  </span>
                                ))}
                              </>
                            )}
                            {factor.group.length > 1 && ' '}
                            {factor.group.replace('->', '→')}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

ProfileResultsFoodIdPreviewPageComponent.defaultProps = {
  answer: undefined,
  foodIdSymptomsGroups: undefined,
  foodIdSymptoms: undefined,
  habits: undefined,
};

export default ProfileResultsFoodIdPreviewPageComponent;
