import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import tw, { styled } from 'twin.macro';
import { up } from 'styled-breakpoints';

import { strings } from '../../localization/strings';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import * as productsApi from '../../services/api/products';
import Product from '../../entities/product';
import IMAGES from '../../assets/images';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';
import CommonButtonQuestion from '../../components/common/buttons/question';
import CommonButtonDefault from '../../components/common/buttons/default';
import { SelectedProduct } from '../../entities/selected-product';
import * as questionnaireApi from '../../services/api/questionnaire';
import { useSite } from '../../hooks/use-site';
import AlertPrimary from '../../components/common/alerts/alert-primary';

const cookies = new Cookies();

const QuestionCentering = styled.div<{ width?: number }>`
  display: inline-block;
  text-align: left;
  padding-top: 2.4rem;
  padding-bottom: 2rem;
  position: relative;
  margin: 0 auto;

  ${up('md')} {
    padding-top: 4rem;
  }

  ${up('xl')} {
    ${(props) => props.width === 1 && `width: 32rem`}
    ${(props) => props.width === 2 && `width: 50rem`}
    ${(props) => props.width === 3 && `width: 75rem`}
  }

  ${up('xl')} {
    padding-top: 4rem;
    min-height: 31rem;
  }
`;

const QuestionContainer = styled.div`
  ${tw`px-4 text-center overflow-x-hidden`}
  margin-top: 0.4rem;
  min-height: 100vh;

  ${up('xl')} {
    margin-top: 3.375rem;
  }

  ${up('md')} {
    margin-top: 2.375rem;
  }
`;

const BgImage = styled.img`
  left: -8.125rem;
  top: 0;
  width: 20.938rem;
  ${tw`absolute z-0`};
`;

const QuestionTitle = styled.h3<{ width?: number }>`
  ${tw`text-xl md:text-3xl leading-10 font-bold relative z-10`};
  line-height: 1.4;
  ${(props) => props.width && `width: ${props.width}rem`}
  ${(props) => (props.width === 3 ? tw`mt-0` : tw`mt-5 md:mt-9`)}
`;

const QuestionBody = styled.div<{ width?: number }>`
  ${tw`relative z-10`}
  ${(props) => (props.width === 3 ? tw`pt-12` : tw`pt-8 md:pt-12`)}
`;

const QuestionOptionsContainer = styled.div`
  ${tw`flex flex-row -mx-2 flex-wrap flex-1`}
`;

const QuestionOptionContainer = styled.div`
  ${tw`px-2 pb-4 w-full xl:w-1/3`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-row pt-1 md:pt-7 justify-end`}
`;

const SpacedButton = styled(CommonButtonDefault)`
  ${tw`ml-5`}
`;

export default function QuestionnaireSelectReviewPageComponent() {
  const hash = cookies.get(COOKIE_NAME_ANSWER);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [products, setProducts] = useState<Product[]>();
  const [selected, setSelected] = useState<SelectedProduct>();
  const navigate = useNavigate();
  const [disableContinue, setDisableContinue] = useState<boolean>(true);
  const { patientId } = useParams();
  const { isAllergomedica } = useSite();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await productsApi.getProducts();

      if (res) {
        const asitProducts = res.filter((product) => product.key === 'SCREENER_ASIT');

        if (asitProducts) {
          setProducts(asitProducts);
        }
      } else {
        setError(strings().error.backendError);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  const onSelectOption = (id: number, index: number) => {
    setSelected({ id, iterationNo: index });
    setDisableContinue(false);
  };

  const onContinue = async () => {
    setIsLoading(true);

    if (selected) {
      const res = await questionnaireApi.selectProduct(hash, selected?.id);

      if (res) {
        setIsLoading(true);
        navigate(`/payments/checkout/${hash}/${patientId}`);
      } else {
        setError(strings().error.backendError);
      }
    }
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      <QuestionContainer>
        <QuestionCentering width={3}>
          <QuestionTitle>{strings().selectConsultationReview.title}</QuestionTitle>
          {error && (
            <div className="mb-5 z-10">
              <AlertPrimary type="danger" title={error} />
            </div>
          )}
          {isAllergomedica && (
            <BgImage
              src={IMAGES.BLOB.FIRST}
              className={`
              '!left-1/2 ml-[-9rem]'
            }`}
              aria-hidden="true"
            />
          )}
          <QuestionBody width={3}>
            <QuestionOptionsContainer>
              {products?.map((option, index) => (
                <QuestionOptionContainer key={option.id}>
                  <CommonButtonQuestion
                    active={index === selected?.iterationNo}
                    className="!w-full"
                    hasCheckbox
                    onClick={() => onSelectOption(option.id, index)}
                    key={option.id}
                  >
                    <div className="font-bold text-black text-lg">
                      {option.key === 'SCREENER_ASIT' && strings().products[option.key].review[option.review].title}
                    </div>
                    <div className="text-blue-500 my-1 text-lg">
                      {option.key === 'SCREENER_ASIT' && strings().products[option.key].review[option.review].price}
                    </div>
                    {option.key === 'SCREENER_ASIT' && strings().products[option.key].review[option.review].description}
                  </CommonButtonQuestion>
                </QuestionOptionContainer>
              ))}
            </QuestionOptionsContainer>

            <ButtonsContainer>
              <SpacedButton
                className="group relative ml-5"
                type="submit"
                primary
                disabled={disableContinue}
                onClick={() => onContinue()}
              >
                <span className="inline-block pr-3"> {strings().button.continue}</span>
                <img
                  src={IMAGES.ICONS.ARROWRIGHT}
                  className="transition-all absolute right-5 top-1/2 -mt-2 group-hover:right-4"
                  alt=""
                />
              </SpacedButton>
            </ButtonsContainer>
          </QuestionBody>
        </QuestionCentering>
      </QuestionContainer>
    </div>
  );
}
